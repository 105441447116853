import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { REACT_APP_STRIPE_PUBLIC_KEY } from "../../utils/pricing-content";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import BackButton from "../../components/BackButton";
import UpdatePaymentDetailsForm from "./UpdatePaymentDetailsForm";
import { REDIRECT_SOURCE_UPDATE_PAYMENT_DETAILS } from "../constants";
import { useGetCurrentSubscriptionQuery } from "../../api/management";
import { NEW_ROUTES } from "../../router";
import Loading from "../../components/Loading";
import { getEmailFromSearchParams } from "../../utils/pricing-content";

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const UpdatePaymentDetails = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const rawEmail = getEmailFromSearchParams()
  const email = encodeURIComponent(rawEmail);

  const {
    data: subscriptionData,
    isLoading,
    isError,
  } = useGetCurrentSubscriptionQuery({ email });

  useEffect(() => {
    const checkSubscriptionDetails = async () => {
      try {

        if (!isLoading && subscriptionData) {
          const { status, price_id, db_plan_id, category } = subscriptionData;

          if (status === "canceled" || isError) {
            const queryParams = new URLSearchParams({
              price: price_id,
              subscription: category,
              postgres_plan_id: db_plan_id,
            }).toString();

            navigate(`${NEW_ROUTES.CHECKOUT}&${queryParams}`, {
              state: { from: REDIRECT_SOURCE_UPDATE_PAYMENT_DETAILS },
            });
          } else {
            setLoading(false);
          }
        }
        
      } catch (error) {
        console.error("Error fetching sub data", error);
        setLoading(false);
      }
    };

    checkSubscriptionDetails();
  }, [subscriptionData, isLoading, isError, navigate]);

  const options = {
    paymentMethodCreation: "manual",
    mode: "setup",
    currency: "usd",
  } as StripeElementsOptions;

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <BackButton showLogo={true} />
        <Elements stripe={stripePromise} options={options}>
          <UpdatePaymentDetailsForm />
        </Elements>
      </div>
    </div>
  );
};

export default UpdatePaymentDetails;
