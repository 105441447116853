import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect, useMemo, useState } from "react";
import { priceUrls } from "./urls";

const stripeUrl =
  process.env.REACT_APP_STRIPE_URL || "https://api.stripe.com/v1/";

const stripeApiKey =
  process.env.REACT_APP_STRIPE_KEY ||
  "rk_test_51KG0VADJtTREqYymJKET6f5GVM9jlPLhNrbLFMF8JU1EtDuftlsM2JSO4RZr54f61ksMh6pAz0kWbhEddx2F5Q7M00eFkpJozp";

export const stripeApi = createApi({
  reducerPath: "stripe",
  baseQuery: fetchBaseQuery({
    baseUrl: stripeUrl,
    prepareHeaders: async (headers) => {
      headers.set("Authorization", `Bearer ${stripeApiKey}`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPrices: builder.query<
      {
        data: {
          unit_amount: number;
          id: string;
          recurring: { interval_count: number };
        }[];
      },
      void
    >({
      query: priceUrls.prices,
    }),
  }),
});

export const { useGetPricesQuery } = stripeApi;

export const useGetPriceQuery = (id: string) => {
  const { data } = useGetPricesQuery();
  const { price, originalPrice } = useMemo(() => {
    const unparsedPrice = data?.data?.find((item) => item.id === id);
    const unit_amount = unparsedPrice?.unit_amount;
    const originalPrice = unit_amount ? unit_amount / 100 : 0;
    let price = originalPrice;
    return {
      price,
      originalPrice,
    };
  }, [data, id]);

  return { data: price, originalPrice };
};

export const useGetMostExpensivePriceQuery = (ids: string[]) => {
  const [mostExpensivePrice, setMostExpensivePrice] = useState(0);

  const { data: allPrices } = useGetPricesQuery();

  useEffect(() => {
    if (allPrices && ids.length > 0) {
      getMostExpensivePrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPrices, ids]);

  const getMostExpensivePrice = () => {
    const prices = allPrices?.data.filter((item) => ids.includes(item.id));

    const mostExpensive = prices?.reduce((max, price) => {
      return price.unit_amount > max ? price.unit_amount : max;
    }, 0);

    mostExpensive && setMostExpensivePrice(mostExpensive);
  };

  return (mostExpensivePrice || 0) / 100;
};
