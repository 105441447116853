import React, { ReactNode, useContext } from "react";
import styles from "../styles.module.scss";
import { Typography } from "../../../../components";
import useIsDesktopMode from "../../../../hooks/useIsDesktopMode";
import PromotionCode from "../PromotionCode";
import useCurrentPlan, {
  Subscriptions,
} from "../../../../hooks/useCurrentPlan";
import { useLocation } from "react-router-dom";
import { useGetPriceQuery } from "../../../../api/stripe";
import { CouponContext } from "../../../../contexts/CouponContext";
import { REDIRECT_SOURCE_UPDATE_PAYMENT_DETAILS } from "../../../constants";
import {
  formatPrice,
  getMatchingFromSearchParams,
  getPriceWithCoupon,
} from "../../../../utils/pricing-content";
import {
  getNewBillingCycleFromQuery,
  getPriceFromQuery,
} from "../../../../utils/getCustomerData";
import useGetSubscriptionTitle from "../../../../hooks/useGetSubscriptionTitle";

const PriceText = ({
  children,
  fontWeight = 500,
  style,
}: {
  children: ReactNode;
  fontWeight?: number;
  style?: { [key: string]: any };
}) => (
  <Typography
    variant="subtitle"
    style={{
      fontWeight,
      color: "#1F2222",
      ...style,
    }}
  >
    {children}
  </Typography>
);

const PriceDetails = () => {
  const isDesktopMode = useIsDesktopMode();
  const plan = useCurrentPlan();
  const { getSubscriptionTitle } = useGetSubscriptionTitle();
  const { state, search } = useLocation();
  const { data: price } = useGetPriceQuery(state?.price || getPriceFromQuery());
  const { coupon } = useContext(CouponContext);
  const billingCycle = getNewBillingCycleFromQuery();
  const matching = getMatchingFromSearchParams(search);
  const priceWithCoupon = getPriceWithCoupon(price, coupon);
  const params = new URLSearchParams(window.location.search);
  const mode = params.get("mode");
  const formattedPriceWithCoupon = formatPrice(priceWithCoupon);
  const formattedOriginalPrice = formatPrice(price);
  const billingCyclePrice = priceWithCoupon / 4;

  const showPromoCode =
    !matching && state?.from !== REDIRECT_SOURCE_UPDATE_PAYMENT_DETAILS;

  return (
    <div className={styles.detailsWrapper}>
      <div className={styles.price}>
        <Typography
          variant="header"
          style={{
            margin: 0,
            width: "fit-content",
            fontWeight: 700,
            fontSize: 22,
          }}
        >
          {billingCycle && !mode
            ? `$${formatPrice(billingCyclePrice)}`
            : `$${formattedPriceWithCoupon}`}
        </Typography>
        {!mode && plan?.name !== Subscriptions.Matching && (
          <Typography
            variant="subtitle"
            style={{
              fontWeight: 600,
              margin: isDesktopMode ? "auto" : "auto 0",
              marginLeft: isDesktopMode ? "10px" : "5px",
              color: "#575961",
              fontSize: isDesktopMode ? "14px" : "10px",
            }}
          >
            {billingCycle ? "per week" : "per month"}
          </Typography>
        )}
      </div>
      <hr className={styles.divider} />
      <PriceText
        fontWeight={600}
        style={{ marginTop: "16px", fontSize: "16px" }}
      >
        Summary
      </PriceText>

      <div className={styles.details}>
        <PriceText>
          {getSubscriptionTitle(state?.price || getPriceFromQuery()) +
            (billingCycle && !mode
              ? `(4x$${formatPrice(+price / 4)})`
              : "")}
        </PriceText>
        <PriceText>${price}</PriceText>
      </div>

      {isDesktopMode && <hr className={styles.divider} />}

      <div className={styles.details}>
        <PriceText>Subtotal</PriceText>
        <PriceText>${price}</PriceText>
      </div>

      {showPromoCode && <PromotionCode />}

      <hr className={styles.divider} />

      <div className={styles.details}>
        <PriceText style={{ fontSize: "16px", fontWeight: 700 }}>
          Total due today
        </PriceText>
        <div className={styles.totalPrice}>
          <Typography
            variant="header"
            style={{ fontWeight: 700, fontSize: 22 }}
          >
            ${formattedPriceWithCoupon}
          </Typography>
        </div>
      </div>
      {!mode && (
        <Typography
          variant="subtitle"
          style={{
            fontSize: "12px",
          }}
        >
          {coupon &&
            `After first month $${formattedOriginalPrice}.`}{" "}
          {billingCycle ? "Billed every 4 weeks." : "Billed monthly."}
        </Typography>
      )}
    </div>
  );
};

export default PriceDetails;
