import React, { useEffect, useState } from "react";
import BackButton from "../../../components/BackButton";
import { Typography } from "../../../components";
import styles from "./styles.module.scss";
import PriceDetails from "./PriceDetails";
import ReviewCard from "../../../components/ReviewCard";
import useIsDesktopMode from "../../../hooks/useIsDesktopMode";
import {
  getMatchingFromSearchParams,
  getPaymentModeFromSearchParams,
} from "../../../utils/pricing-content";
import { useLocation } from "react-router-dom";
import { useGetReviewsQuery } from "../../../api/cms";
import { Review } from "../../../api/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import useGetSubscriptionTitle from "../../../hooks/useGetSubscriptionTitle";
import { getPriceFromQuery } from "../../../utils/getCustomerData";

const CheckoutDetails = () => {
  const isDesktopMode = useIsDesktopMode();
  const [review, setReview] = useState<Review>();
  const { state, search } = useLocation();
  const { data: reviews } = useGetReviewsQuery();
  const selectedReviewId = 1;
  const { matchingPricing } = useFlags();
  const matching = getMatchingFromSearchParams(search);
  const mode = getPaymentModeFromSearchParams();
  const { getSubscriptionTitle } = useGetSubscriptionTitle();
  const subscribeText = !(matching && matchingPricing && mode)
    ? "Subscribe to "
    : "";

  useEffect(() => {
    if (reviews) {
      setReview(reviews.find((review) => review.id === selectedReviewId));
    }
  }, [reviews]);

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <BackButton showLogo={true} />
        </div>
        <div className={styles.wrapper}>
          <Typography
            variant="subtitle"
            style={{
              fontWeight: 600,
              textAlign: "center",
              color: "#1F2222",
              fontSize: 16,
              lineHeight: "24px",
            }}
          >
            {subscribeText}OurRitual -{" "}
            {getSubscriptionTitle(state?.price || getPriceFromQuery())}
          </Typography>
        </div>

        <PriceDetails />

        {isDesktopMode && review && (
          <ReviewCard
            review={review?.Description}
            rate={review?.rating}
            name={review?.Author}
          />
        )}
      </div>
    </div>
  );
};

export default CheckoutDetails;
