import React from "react";
import Discount from "../../assets/icons/Discount";
import { Typography } from "../index";
import { useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { getMatchingFromSearchParams } from "../../utils/pricing-content";
import styles from "./styles.module.scss";

const MatchingDiscount = () => {
  const { search } = useLocation();
  const { matchingPricing } = useFlags();
  const matching = getMatchingFromSearchParams(search);

  return (
    matchingPricing &&
    matching && (
      <div className={styles.matching_discount}>
        <Discount />
        <Typography>Matching session discount applied</Typography>
      </div>
    )
  );
};

export default MatchingDiscount;
