import React from "react";
import CheckMark from "../../assets/icons/CheckMark";
import styles from "./styles.module.scss";

const TierFeatures = ({ features }: { features: string }) => {
  return (
    <div className={styles.wrapper}>
      <p>Here’s what you get:</p>
      {features.split("\n").map((item) => (
        <FeatureItem key={item} text={item} />
      ))}
    </div>
  );
};

const FeatureItem = ({ text }: { text: string }) => (
  <div
    className={styles.tier_feature}
    id={text.replace(" ", "_").toLowerCase()}
  >
    <div>
      <CheckMark />
    </div>
    <p>{text}</p>
  </div>
);

export default TierFeatures;
