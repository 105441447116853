import { buildUrl } from "./cmsUrlBuilder";

export const cmsUrls = {
  plans: () =>
    buildUrl("pricing-plans", {
      populate: ["*", "default_tier.initial_price", "image", "card_image"],
    }),
  prices: ({ tierId, stripeId }: { tierId?: string; stripeId?: string }) => {
    const filters = [];

    if (stripeId) {
      filters.push({ matchers: ["stripe_id", "$eq"], value: stripeId });
    }

    if (tierId) {
      filters.push({ matchers: ["tier", "id", "$eq"], value: tierId });
    }

    return buildUrl("prices", {
      populate: ["*", "tier.label_image", "tier.plan"],
      filters,
    });
  },
  tiers: (planId: string) =>
    buildUrl("pricing-tiers", {
      populate: ["*"],
      filters: [{ matchers: ["plan", "id", "$eq"], value: planId }],
    }),
  reviews: () =>
    buildUrl("reviews", {
      populate: ["*"],
    }),
  desktopStaticContent: () => buildUrl("pricing-desktop-page"),

  faqQuestions: () =>
    buildUrl("faq-questions", {
      pagination: [{ matchers: ["limit"], value: "-1" }],
      filters: [{ matchers: ["pages", "$contains"], value: "pricing" }],
    }),

  allPlansDescription: () =>
    buildUrl("all-plans-descriptions", {
      filters: [{ matchers: ["type", "$contains"], value: "all-plans" }],
      populate: ["*"],
    }),
  relationExpertDescription: () =>
    buildUrl("all-plans-descriptions", {
      filters: [{ matchers: ["type", "$contains"], value: "expert" }],
      populate: ["*"],
    }),
};

export const priceUrls = {
  prices: () => `/prices?limit=100`,
};

export const managementUrls = {
  coupon: (name: string) => `/coupons/${name}/`,
  createPayment: "/payments/create-payment/",
  createMember: "/public/create-member/",
  fetchCurrentSubscription: ({ email }: { email: string }) =>
    `/subscriptions/current?email=${email}`,
  checkNeedToUpdatePaymentDetails: ({
    email,
    price,
  }: {
    email: string;
    price: string;
  }) =>
    `/public/check-need-to-update-payment-details/?email=${email}&price=${price}`,
  updatePaymentDetails: "/payments/update-payment-details/",
  createMatchingSessionSubscription:
    "/public/create-matching-session-subscription/",
  fetchPlansDetails: "/plans/public-plans",
};
