import { PriceCardProps } from ".";
import { useGetPriceQuery } from "../../api/stripe";
import OriginalPrice from "../OriginalPrice";
import useIsDesktopMode from "../../hooks/useIsDesktopMode";
import Typography from "../Typography";
import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  getMatchingFromSearchParams,
  getPaymentModeFromSearchParams,
  getPriceWithCoupon,
  getSavingsPercentage,
} from "../../utils/pricing-content";
import React, { useContext } from "react";
import { getNewBillingCycleFromQuery } from "../../utils/getCustomerData";
import { CouponContext } from "../../contexts/CouponContext";

const RepackagingPriceCard = ({
  title,
  priceId,
  cardLabel,
  selected,
  mostExpensivePrice,
}: PriceCardProps) => {
  const { data: price, originalPrice } = useGetPriceQuery(priceId);
  const { coupon } = useContext(CouponContext);
  const priceWithCoupon = getPriceWithCoupon(price, coupon);
  const isDesktopMode = useIsDesktopMode();
  const { matchingPricing, newBillingCycle } = useFlags();
  const billingCycle = getNewBillingCycleFromQuery();
  const { search } = useLocation();
  const matching = getMatchingFromSearchParams(search);
  const calculatedSavings =
    price && mostExpensivePrice
      ? getSavingsPercentage(price, mostExpensivePrice)
      : 0;
  const mode = getPaymentModeFromSearchParams();
  const billingCyclePrice = matching ? priceWithCoupon : price;

  return (
    <div
      className={`${styles.wrapper}  ${
        selected ? styles["wrapper-selected"] : ""
      } ${styles.repackaging_wrapper}
      `}
    >
      {cardLabel && (
        <div
          className={`${styles.card_label} ${styles.label} ${styles["card_label-repackaging"]}`}
        >
          {cardLabel}{" "}
          {calculatedSavings !== 0 && `- SAVE ${calculatedSavings}%`}
        </div>
      )}
      <div className={styles.wrapper_main}>
        <div className={styles.repackaging_title}>
          <Typography variant="title">{title}</Typography>
        </div>
        {!billingCycle && (
          <div className={styles.price_wrapper}>
            <OriginalPrice
              price={matching ? priceWithCoupon : price}
              originalPrice={originalPrice}
            />
            <Typography variant="price">
              ${matching ? priceWithCoupon : price}
            </Typography>
            <div className={styles["repackaging-price_wrapper"]}>
              <Typography style={{ position: "static" }}>
                {isDesktopMode ? "per month" : "/month"}
              </Typography>
            </div>
          </div>
        )}
        {newBillingCycle && billingCycle && (
          <div className={styles.price_wrapper}>
            <Typography variant="price">
              ${Math.ceil(billingCyclePrice / 4)}
              {matching && matchingPricing && !mode && "*"}
            </Typography>
            <div className={styles["repackaging-price_wrapper"]}>
              <Typography style={{ position: "static" }}>
                {isDesktopMode ? "per week" : "/week"}
              </Typography>
            </div>
          </div>
        )}
      </div>
      {matching && matchingPricing && !mode && (
        <div className={styles.price_after_container}>
          <span className={styles.price_after}>
            *For the first month (Total ${billingCyclePrice})
          </span>
        </div>
      )}
    </div>
  );
};

export default RepackagingPriceCard;
